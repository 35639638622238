<template>
  <VDialog
    :model-value="shouldShow"
    :fullscreen="display.smAndDown.value"
    :persistent="true"
    :max-width="900"
  >
    <div
      class="bg-white h-screen overflow-auto md:!overflow-hidden md:!h-full md:rounded-3xl p-4 md:p-10 flex flex-col items-center"
    >
      <ConfettiExplosion class="mx-auto" />

      <div class="relative flex w-full mt-4 md:mt-0">
        <VProgressLinear
          height="10"
          :model-value="progress"
          color="primary"
          rounded="full"
          class="mb-10"
        />

        <VBtn
          v-if="step > 0"
          class="absolute -left-2 top-1 -translate-y-1/2"
          rounded="full"
          @click="goBackStep"
        >
          <VIcon icon="mdi-chevron-left" size="30" />
        </VBtn>
      </div>

      <Component
        :is="currentStepComponent.component"
        :key="currentStepComponent.key"
      />

      <VBtn
        flat
        rounded="full"
        color="primary"
        size="large"
        class="mt-8"
        @click="goToNextStep"
      >
        <span class="px-4">Continuar</span>
      </VBtn>
    </div>
  </VDialog>
</template>

<script lang="ts" setup>
import ConfettiExplosion from "vue-confetti-explosion";
import { useOnboarding } from "../composables/useOnboarding";
import { useDisplay } from "vuetify";

const {
  shouldShow,
  progress,
  currentStepComponent,
  step,
  goBackStep,
  goToNextStep,
} = useOnboarding();

const display = useDisplay();
</script>
