<template>
  <VDialog
    v-model="isOpened"
    max-width="800"
    :fullscreen="display.smAndDown.value"
    class="md:overflow-hidden"
  >
    <VCard class="md:!rounded-[40px]">
      <div class="relative md:overflow-hidden">
        <div class="md:h-[calc(100vh-48px)] overflow-auto">
          <div class="p-4 max-w-[650px] mx-auto">
            <div class="flex">
              <UILoading :open="isPending" class="mx-auto" />
            </div>
            <div v-if="data">
              <!-- Header -->
              <div>
                <h1 class="text-3xl mt-2">
                  {{ data.title }}
                </h1>
              </div>

              <!-- Body -->
              <ContentBody class="mt-2" :html="data.content" />
            </div>

            <!-- Close button -->
            <VBtn
              icon="mdi-close"
              size="small"
              class="shadow-xl fixed md:absolute top-4 right-4 border-[1px] !border-black/50"
              @click="close"
            />
          </div>
        </div>
      </div>
    </VCard>
  </VDialog>
</template>

<script lang="ts" setup>
import { ContentBody } from "~/domains/content/components";
import { useNotificationViewer } from "../composables/useNotificationViewer";
import { UILoading } from "~/domains/global/components";
import { useGetNotificationAndMarkAsRead } from "../mutations/getNotificationAndMarkAsRead";
import { useDisplay } from "vuetify";

const { isOpened, currentNotificationId, close } = useNotificationViewer();
const { isPending, data, mutate } = useGetNotificationAndMarkAsRead();

const display = useDisplay();

watch(isOpened, (value) => {
  if (value && currentNotificationId.value) {
    mutate(currentNotificationId.value);
  }
});
</script>
