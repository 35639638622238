import { toast } from 'vue3-toastify'
import { OnboardingSteps } from '../components/steps'
import { useSaveOnboardingDataMutation } from '../mutations/useSaveOnboardingDataMutation'
import { usePreference } from '~/domains/auth/composables/usePreference'

export type OnboardingData = typeof data

const data = reactive({
  exam: '',
  lastEnemGrade: '',
  concursoName: '',
  vestibularName: '',
  goal: '',
  source: '',
})

const step = ref(0)
const showFirstSteps = ref(false)

export const useOnboarding = () => {
  const route = useRoute()
  const router = useRouter()

  const { mutate } = useSaveOnboardingDataMutation()

  const welcomePreference = usePreference({
    key: 'welcome',
    defaultValue: 0,
    cast: Number,
  })

  const shouldShow = computed(() => !welcomePreference.value)

  const progress = computed(() => {
    return ((step.value + 1) * 100) / OnboardingSteps.length
  })

  const currentStepComponent = computed(() => {
    return OnboardingSteps[step.value]
  })

  // Watch route query to update step
  watch(() => route.query.etapa, () => {
    const etapa = route.query.etapa as string ?? '0'
    step.value = parseInt(etapa)
  })

  const goToNextStep = () => {
    let nextStep = step.value
    try {
      nextStep = validate()
    }
    catch (error: any) {
      return toast.error(error.message)
    }

    if (nextStep >= OnboardingSteps.length) {
      return finishOnboarding()
    }

    router.push({
      query: {
        etapa: String(nextStep),
      },
    })
  }

  const goBackStep = () => {
    let nextStep = step.value - 1
    if (nextStep < 0) {
      nextStep = 0
    }

    if (nextStep === 2 && data.exam === 'FUVEST') {
      nextStep = 1
    }

    router.push({
      query: {
        etapa: String(nextStep),
      },
    })
  }

  const finishOnboarding = () => {
    welcomePreference.value = 1
    mutate(data)

    showFirstSteps.value = true
  }

  return {
    progress,
    data,
    step,
    welcomePreference,
    shouldShow,
    currentStepComponent,
    showFirstSteps,
    goToNextStep,
    goBackStep,
  }
}

// Validate if the user has filled all the required fields
// @returns the next step if all fields are filled
// @throws an error if a field is missing
const validate = () => {
  if (step.value === 1 && !data.exam) {
    throw new Error('Selecione uma opção')
  }

  const exam = data.exam

  if (step.value === 1 && exam === 'FUVEST') {
    return step.value + 2
  }

  if (step.value === 2) {
    if (exam === 'ENEM' && !data.lastEnemGrade) {
      throw new Error('Selecione uma opção')
    }
    else if (
      exam === 'CONCURSO'
      && !data.concursoName
    ) {
      throw new Error('Digite o nome do concurso')
    }
    else if (
      exam === 'OUTRO_VESTIBULAR'
      && !data.vestibularName
    ) {
      throw new Error('Digite o nome do vestibular')
    }
  }

  if (step.value === 3 && !data.goal) {
    throw new Error('Selecione uma opção')
  }

  if (step.value === 4 && !data.source) {
    throw new Error('Selecione uma opção')
  }

  return step.value + 1
}
