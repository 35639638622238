import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { useApi } from "~/domains/global/composables/useApi";
import type { UserNotification } from "../queries/getNotificationsQuery";

export const useGetNotificationAndMarkAsRead = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (notificationId: string) => {
      const api = useApi();

      const notification = await api<Notification>(
        `/notification/read/${notificationId}`,
        {
          method: "GET",
        }
      );

      // await 1s
      // await new Promise((resolve) => setTimeout(resolve, 2000));

      return notification;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["notifications"],
        (notifications: UserNotification[]) => {
          if (notifications) {
            return notifications.map((notification) => {
              if (notification.id === data.id) {
                return { ...notification, read: true };
              }

              return notification;
            });
          }

          return notifications;
        }
      );

      return data;
    },
  });
};

export type Notification = {
  id: string;
  title: string;
  content: string;
  createdAt: string;
};
